import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import styles from '../styles/Home.module.css';
import Header from '../src/components/Header';
import CONSTANTS from '../src/constants';
import { translate as t } from '../src/language/translation';
// const urlDev = 'https://module-b-portal.icanbwell.com';
const urlProd = 'https://access.medstarhealth.org/';
const url = urlProd;

export default function Home(props) {
  const router = useRouter();

  useEffect(() => {
    router.push(url);
  }, []);

  return (
    <React.Fragment>
      {/* {router.push(url)} */}
      <main id="landing-main" className={styles.main}>
        <Header heading={t(CONSTANTS.INTRO_TITLE_DISPLAY)} />
        <p>
          We are redirecting you to <a href={url}>{url}</a> If you are not
          redirected in 10 seconds, please click the link.
        </p>
      </main>
    </React.Fragment>
  );
}
